import { useRootSelector } from '../../../../../../store/hooks';
import { selectPricingPackagesCount } from '../../../../store/features/packages';
import { getTicketsCount } from '../../../../helpers/basket/getTicketsCount';
import PaymentProvider from '../../../PaymentProvider';
import MiniBasketButton from '../MiniBasketButton';

export default function SiteMiniBasket() {
  const packagesCount = useRootSelector(selectPricingPackagesCount);
  const ticketsCount = getTicketsCount(packagesCount);

  if (!ticketsCount) {
    return null;
  }

  return (
    <PaymentProvider>
      <MiniBasketButton ticketsCount={ticketsCount} sx={{ mr: 2 }} />
    </PaymentProvider>
  );
}
